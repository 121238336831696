import { API_ROOTS, API_ROOTS_V2 } from "./constants";
var checkRoot = function (env) {
    return function (root, current) { var _a; return ((_a = current.test) === null || _a === void 0 ? void 0 : _a.call(current, env)) ? current.url : root; };
};
var reduceApiRoot = function (roots, env) {
    return Object.values(roots).reduce(checkRoot(env), roots.prod.url);
};
export var getApiRoot = function (env) {
    return reduceApiRoot(API_ROOTS, env);
};
export var getApiRootV2 = function (env) {
    return reduceApiRoot(API_ROOTS_V2, env);
};
export function getBaseUrl(_a) {
    var trellisEnv = _a.trellisEnv, apiRoot = _a.apiRoot;
    return apiRoot || getApiRoot(trellisEnv);
}
