import { TrellisEnvs } from "../../constants";
import isLocalHost from "../../utils/isLocalHost";
export var INVALID_TOKEN_RESPONSE = 400;
export var API_ROOTS = {
    local: {
        url: "http://localhost:3000/trellis/connect/1.1.0",
        test: isLocalHost,
    },
    dev: {
        url: "https://us-central1-trellisconnect-dev.cloudfunctions.net/trellisApiDev/trellis/connect/1.1.0",
        test: function (env) { return env === TrellisEnvs.Dev; },
    },
    prod: {
        url: "https://api.trellisconnect.com/trellis/connect/1.1.0",
        test: function (env) { return env === TrellisEnvs.QA || env === TrellisEnvs.Prod; },
    },
};
export var API_ROOTS_V2 = {
    local: {
        url: "http://localhost:3000/trellis/connect/1.2.0",
        test: isLocalHost,
    },
    dev: {
        url: "https://us-central1-trellisconnect-dev.cloudfunctions.net/trellisApiDev/trellis/connect/1.2.0",
        test: function (env) { return env === "dev"; },
    },
    prod: {
        url: "https://api.trellisconnect.com/trellis/connect/1.2.0",
        test: function (env) { return env === "qa" || env === "prod"; },
    },
};
export var issuersAndPartnerQuery = "\n  fragment issuerFields on Issuer {\n    id\n    name\n    favicon\n    icon\n    color\n    pin\n    mfa\n    rank\n    site\n    usernameFieldPlaceholder\n    passwordFieldPlaceholder\n    thirdFieldPlaceholder\n    accountLabel\n  }\n  query initialLoad($key: String!) {\n    partner: validateClientId(clientId: $key) {\n      name\n      id\n      skipTrellisConsent\n    }\n    issuers: getIssuers {\n      ...issuerFields\n    }\n  }\n";
