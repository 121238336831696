var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { InsuranceProducts, InsuranceType, OnboardExperienceVersions, POST_MESSAGE_TYPES, } from "./constants";
import buildIframeURL from "./utils/buildIframeUrl";
import { getApiRootV2 } from "./services/connect/getApiRoot";
import noop from "./utils/noop";
import { v4 as uuid } from "uuid";
import validateReceivedMessage from "./utils/validateReceivedMessage";
var TRELLIS_CDN_URL = process.env.TRELLIS_CDN_URL;
var BUILD_HASH = process.env.BUILD_HASH;
// Module scoped flag to prevent consumers from attempting to configure
// the widget multiple times.
var configureCalled = false;
// We need to hold on to a reference of the configure's handler return
// in order to not squash a consumer's potential extra config call.
var handlerReference = {
    open: open,
    destroy: destroy,
    resetState: resetState,
    back: back,
    exit: exit,
};
var widgetOpen = false;
var iframeContainer;
var iframeIsLoaded = false;
var debug = false;
var iframe;
var prevOverflow;
var messageListener;
// Element that the iframe gets appended to
var rootElement;
function trellisIeVersion(uaString) {
    if (uaString === void 0) { uaString = navigator.userAgent; }
    var match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
    if (match && match[2]) {
        return parseInt(match[2]);
    }
    return null;
}
function configure(params) {
    internalConfigure(params);
    return handlerReference;
}
function open(_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.sendMessage, sendMessage = _c === void 0 ? true : _c, product = _b.product;
    setTimeout(function () {
        if (iframeContainer) {
            iframeContainer.style.display = "block";
        }
    }, product ? 200 : 0);
    history.pushState({ widgetOpen: true }, document.title);
    rootElement.style.overflow = "hidden";
    if (iframeIsLoaded) {
        openIframe({ sendMessage: sendMessage, product: product });
    }
    else if (iframe) {
        iframe.onload = function () {
            openIframe({ sendMessage: sendMessage, product: product });
        };
    }
}
function close() {
    widgetOpen = false;
    rootElement.style.overflow = prevOverflow;
    if (iframeContainer) {
        iframeContainer.style.display = "none";
    }
    if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ source: "trellis", name: POST_MESSAGE_TYPES.CLOSE }, "*");
    }
}
function openIframe(_a) {
    var sendMessage = _a.sendMessage, product = _a.product;
    widgetOpen = true;
    if (sendMessage && iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ source: "trellis", name: POST_MESSAGE_TYPES.OPEN, data: { product: product } }, "*");
    }
}
function destroy() {
    if (widgetOpen) {
        console.warn("Can't destroy the widget if it is open");
        return;
    }
    if (iframe) {
        iframe.remove();
        iframe = undefined;
    }
    if (iframeContainer) {
        iframeContainer.remove();
        iframeContainer = undefined;
    }
    // reset module state
    configureCalled = false;
    iframeIsLoaded = false;
    widgetOpen = false;
    window.removeEventListener("message", messageListener, false);
}
function back() {
    if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ source: "trellis", name: POST_MESSAGE_TYPES.NAVIGATE_BACK }, "*");
    }
}
function resetState() {
    if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ source: "trellis", name: POST_MESSAGE_TYPES.RESET_STATE }, "*");
    }
}
function exit(force) {
    if (iframe && iframe.contentWindow) {
        var postMessageName = force
            ? POST_MESSAGE_TYPES.FORCED_EXIT
            : POST_MESSAGE_TYPES.EXIT;
        iframe.contentWindow.postMessage({ source: "trellis", name: postMessageName }, "*");
    }
}
function internalConfigure(_a) {
    var _b = _a.autoOpen, autoOpen = _b === void 0 ? false : _b, key = _a.key, client_id = _a.client_id, userId = _a.userId, 
    // We create our own anonymousId and pass it to segment.  This gives us complete control of the timing of when
    // the anonymousId is generated so we don't have to wait for segment to give one to us inside the SDK
    _c = _a.anonymousId, 
    // We create our own anonymousId and pass it to segment.  This gives us complete control of the timing of when
    // the anonymousId is generated so we don't have to wait for segment to give one to us inside the SDK
    anonymousId = _c === void 0 ? uuid() : _c, apiRoot = _a.apiRoot, _d = _a.webhook, webhook = _d === void 0 ? "" : _d, _e = _a.policyOnly, policyOnly = _e === void 0 ? true : _e, _f = _a.authOnly, authOnly = _f === void 0 ? false : _f, _g = _a.delayFullStory, delayFullStory = _g === void 0 ? false : _g, _h = _a.disableFullStory, disableFullStory = _h === void 0 ? false : _h, preselectedIssuerSlug = _a.preselectedIssuerSlug, _j = _a.onSuccess, onSuccess = _j === void 0 ? standInSuccessHandler : _j, _k = _a.onFailure, onFailure = _k === void 0 ? standInFailureHandler : _k, _l = _a.onClose, onClose = _l === void 0 ? standInCloseHandler : _l, _m = _a.onEvent, onEvent = _m === void 0 ? standInEventHandler : _m, _o = _a.track, track = _o === void 0 ? standInTrackHanlder : _o, _p = _a.page, page = _p === void 0 ? standInPageHandler : _p, _q = _a.identify, identify = _q === void 0 ? standInIdentifyHandler : _q, _r = _a.localDev, localDev = _r === void 0 ? false : _r, _s = _a.localDevClient, localDevClient = _s === void 0 ? false : _s, _t = _a.features, features = _t === void 0 ? "" : _t, _u = _a.connectionId, connectionId = _u === void 0 ? null : _u, urlTrackingParams = _a.urlTrackingParams, savvyAnonymousId = _a.savvyAnonymousId, savvyClientId = _a.savvyClientId, savvyUserId = _a.savvyUserId, _v = _a.isSavvyWidget, isSavvyWidget = _v === void 0 ? false : _v, _w = _a.showOnCloseAds, showOnCloseAds = _w === void 0 ? false : _w, _x = _a.insuranceType, insuranceType = _x === void 0 ? InsuranceType.Auto : _x, context = _a.context, application_id = _a.application_id, _y = _a.onLoad, onLoad = _y === void 0 ? noop : _y, experience = _a.experience, skipQualificationQuestions = _a.skipQualificationQuestions, _z = _a.closeConfirmation, closeConfirmation = _z === void 0 ? true : _z, 
    // The user object allows clients to provide additional information about the user
    _0 = _a.user, 
    // The user object allows clients to provide additional information about the user
    user = _0 === void 0 ? {} : _0, isWebView = _a.isWebView, 
    // Chime beta features
    _1 = _a.enableFormExperience, 
    // Chime beta features
    enableFormExperience = _1 === void 0 ? false : _1, // Enable Savvy's form experience
    _2 = _a.openOnResults, // Enable Savvy's form experience
    openOnResults = _2 === void 0 ? false : _2, 
    // Root element to append the iframe to
    containerElement = _a.containerElement, _3 = _a.hideUninsuredButton, hideUninsuredButton = _3 === void 0 ? false : _3, manualFormsButtonText = _a.manualFormsButtonText, _4 = _a.hideLifecycleConsentScreen, hideLifecycleConsentScreen = _4 === void 0 ? false : _4, _5 = _a.closeOnUnsupportedIssuer, closeOnUnsupportedIssuer = _5 === void 0 ? false : _5, _6 = _a.products, products = _6 === void 0 ? [InsuranceProducts.Auto] : _6, _7 = _a.onboardExperience, onboardExperience = _7 === void 0 ? OnboardExperienceVersions.v1 : _7, accountReferenceId = _a.accountReferenceId;
    var startTime = performance.now();
    if (configureCalled) {
        return handlerReference;
    }
    configureCalled = true;
    var containerIsValid = Boolean(containerElement) && containerElement instanceof Element;
    rootElement = document.body;
    if (containerIsValid) {
        rootElement = containerElement;
    }
    else if (containerElement !== undefined) {
        console.warn("Invalid type for containerElement");
    }
    prevOverflow = rootElement.style.overflow || "";
    var finalClientId = key || client_id;
    var client_user_id = user.client_user_id;
    // we are not throwing here in order to throw in `popup-entry` and catch
    // the error in our Sentry logs. No key, no buen
    if (!finalClientId) {
        console.warn("A key or client_id is required");
    }
    var unsupportedIeVersion = 11;
    var urlParams = new URLSearchParams(window.location.search);
    // TODO: localDevClient is only a temp solution, needs a better solution
    var trellisRoot = localDevClient
        ? "http://localhost:1234/local.html"
        : localDev
            ? "/local.html"
            : "https://".concat(TRELLIS_CDN_URL, "/sdk/v1.1/js-sdk/trellis.html");
    iframeContainer = document.createElement("div");
    iframeContainer.id = "trellisconnect-iframecontainer";
    iframeContainer.style.cssText = "\n      position: ".concat(containerIsValid ? "absolute" : "fixed", ";\n      top: 0px;\n      left: 0px;\n      right: 0px;\n      bottom: 0px;\n      z-index: 9447192;\n      border-width: 0px;\n      display: ").concat(autoOpen ? "block" : "none", ";\n      overflow: hidden auto;\n      background-color: rgba(17,17,17,0.9);\n      background-image: url('https://").concat(TRELLIS_CDN_URL, "/sdk/v1.1/js-sdk/assets/icons/loading_3.svg');\n      background-size: 75px 75px;\n      background-position: center;\n      background-repeat: no-repeat;\n      width: 100%;\n      height: 100%;\n    ");
    rootElement.appendChild(iframeContainer);
    iframe = document.createElement("iframe");
    iframe.id = "trellisconnect-iframe";
    var debugKey = urlParams.get("t") === "1";
    if (debugKey) {
        debug = true;
    }
    var utmSource = urlParams.get("utm_source");
    var utmMedium = urlParams.get("utm_medium");
    var utmContent = urlParams.get("utm_content");
    var utmCampaign = urlParams.get("utm_campaign");
    var utmTerm = urlParams.get("utm_term");
    var externalPartnerId = urlParams.get("external_partner_id");
    var trellisEnv = urlParams.get("trellis_env");
    var envKey = urlParams.get("trellis_env");
    var isSavvyAgent = urlParams.get("is_savvy_agent") === "true";
    var gclid = urlParams.get("gclid");
    iframe.src = buildIframeURL(trellisRoot, {
        buildHash: BUILD_HASH !== null && BUILD_HASH !== void 0 ? BUILD_HASH : "",
        connectionId: connectionId,
        anonymousId: anonymousId,
        apiRoot: apiRoot,
        authOnly: authOnly,
        context: context,
        application_id: application_id,
        debugKey: debugKey,
        delayFullStory: delayFullStory,
        disableFullStory: disableFullStory,
        envKey: envKey,
        externalPartnerId: externalPartnerId,
        features: features,
        isSavvyWidget: isSavvyWidget,
        key: finalClientId,
        policyOnly: policyOnly,
        preselectedIssuerSlug: preselectedIssuerSlug,
        // Until Savvy Widget is separated, we need to plumb Savvy's anonymous ID through the widget,
        // so we can associate Savvy Widget events with Savvy Backend events.
        savvyAnonymousId: savvyAnonymousId,
        savvyClientId: savvyClientId,
        savvyUserId: savvyUserId,
        showOnCloseAds: showOnCloseAds,
        insuranceType: insuranceType,
        trellisEnv: trellisEnv,
        urlTrackingParams: urlTrackingParams,
        userId: userId,
        utmContent: utmContent,
        utmMedium: utmMedium,
        utmSource: utmSource,
        utmCampaign: utmCampaign,
        utmTerm: utmTerm,
        webhook: webhook,
        experience: experience,
        skipQualificationQuestions: skipQualificationQuestions,
        closeConfirmation: closeConfirmation,
        client_user_id: client_user_id,
        isWebView: isWebView,
        enableFormExperience: enableFormExperience,
        hideUninsuredButton: hideUninsuredButton,
        manualFormsButtonText: manualFormsButtonText,
        hideLifecycleConsentScreen: hideLifecycleConsentScreen,
        closeOnUnsupportedIssuer: closeOnUnsupportedIssuer,
        products: products,
        onboardExperience: onboardExperience,
        accountReferenceId: accountReferenceId,
        isSavvyAgent: isSavvyAgent,
        gclid: gclid,
    });
    iframe.style.cssText = "\n    position: ".concat(containerIsValid ? "absolute" : "fixed", ";\n    top: 0px;\n    left: 0px;\n    right: 0px;\n    bottom: 0px;\n    z-index: 9447192;\n    border-width: 0px;\n    display: block;\n    overflow: hidden auto;");
    iframe.width = "100%";
    iframe.height = "100%";
    var ieVersion = trellisIeVersion();
    if (ieVersion && ieVersion <= unsupportedIeVersion) {
        var ieRoot = localDevClient
            ? "https://localhost:1234/ie.html"
            : localDev
                ? "/src/ie.html"
                : "https://".concat(TRELLIS_CDN_URL, "/sdk/v1.1/js-sdk/ie.html");
        iframe.src = ieRoot;
    }
    iframe.addEventListener("load", function () {
        iframeIsLoaded = true;
    });
    iframeContainer.appendChild(iframe);
    messageListener = createMessageListener({
        localDev: localDev,
        onSuccess: onSuccess,
        onFailure: onFailure,
        onClose: onClose,
        onEvent: onEvent,
        onLoad: onLoad,
        track: track,
        page: page,
        identify: identify,
        openOnResults: openOnResults,
        autoOpen: autoOpen,
        startTime: startTime,
        clientId: finalClientId,
        envKey: envKey,
    });
    window.addEventListener("message", messageListener, false);
}
function createMessageListener(_a) {
    var localDev = _a.localDev, onSuccess = _a.onSuccess, onFailure = _a.onFailure, onClose = _a.onClose, onEvent = _a.onEvent, onLoad = _a.onLoad, track = _a.track, page = _a.page, identify = _a.identify, openOnResults = _a.openOnResults, autoOpen = _a.autoOpen, startTime = _a.startTime, clientId = _a.clientId, envKey = _a.envKey;
    return function (event) {
        var IS_VALID_MESSAGE = validateReceivedMessage(event, { localDev: localDev });
        if (IS_VALID_MESSAGE === false) {
            return;
        }
        var data = event.data;
        switch (data.name) {
            case "onSuccess":
                if (onSuccess) {
                    onSuccess(data.data.connectionId, data.data.metadata);
                }
                break;
            case "onFailure":
                if (onFailure) {
                    onFailure(data.data.reason);
                }
                break;
            case "onClose":
                close();
                if (onClose) {
                    onClose(data.data.error, data.data.metadata);
                }
                break;
            case "onLoaded": {
                var endTime = performance.now();
                // Remove the loading background image from the loading container once the iframe has loaded
                if (iframeContainer) {
                    iframeContainer.style.backgroundImage = "none";
                }
                if (autoOpen) {
                    open();
                }
                var API_ROOT = getApiRootV2(envKey);
                try {
                    fetch("".concat(API_ROOT, "/metrics"), {
                        method: "POST",
                        headers: {
                            "X-API-CLIENT-ID": clientId,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            timing: endTime - startTime,
                        }),
                    });
                }
                catch (e) {
                    // do nothing with error
                }
                break;
            }
            case "onEvent":
                if (onEvent) {
                    onEvent(data.data.event, data.data.payload);
                }
                break;
            case "track":
                if (track) {
                    track(data.data.event, data.data.params);
                }
                break;
            case "page":
                if (page) {
                    page(data.data.page, data.data.params);
                }
                break;
            case "identify":
                if (identify) {
                    identify(data.data.traits);
                }
                break;
            case "onQuoteLoaded":
                if (openOnResults) {
                    open({ sendMessage: false });
                }
                break;
            case "onLoad":
                if (onLoad) {
                    onLoad(data.data.metadata);
                }
                break;
            default:
                break;
        }
    };
}
function debugLog(message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (debug) {
        console.log.apply(console, __spreadArray([message], args, false));
    }
}
function standInEventHandler(event, payload) {
    debugLog("Trellis Event:", event, payload);
}
function standInTrackHanlder(event, params) {
    debugLog("Track called:", event, params);
}
function standInPageHandler(page, params) {
    debugLog("Page called:", page, params);
}
function standInIdentifyHandler(traits) {
    debugLog("Identify successfully called", traits);
}
function standInSuccessHandler(connectionId, metadata) {
    debugLog("Account successfully connected, but no onSuccess handler has been provided.", connectionId, metadata);
}
function standInFailureHandler(reason) {
    debugLog("Account connection failed, but no onFailure handler has been provided.", reason);
}
function standInCloseHandler(error, metadata) {
    debugLog("Close called, but no onClose handler has been provided.", error, metadata);
}
function initDOMEvents() {
    window.addEventListener("popstate", function () {
        if (!widgetOpen || !iframe || !iframe.contentWindow) {
            return;
        }
        iframe.contentWindow.postMessage({ source: "trellis", name: POST_MESSAGE_TYPES.CLICKED_BACK_BUTTON }, "*");
    });
}
if (/interactive|complete/.test(document.readyState)) {
    initDOMEvents();
}
else {
    window.addEventListener("DOMContentLoaded", function () {
        initDOMEvents();
    });
}
export var TrellisConnect = {
    configure: configure,
};
window.TrellisConnect = TrellisConnect;
