var TRELLIS_CDN_URL = process.env.TRELLIS_CDN_URL || "";
export default function validateReceivedMessage(messageEvent, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.localDev, localDev = _c === void 0 ? false : _c;
    var _d = messageEvent.data, data = _d === void 0 ? {} : _d, _e = messageEvent.origin, origin = _e === void 0 ? "" : _e;
    var IS_VALID_LOCALHOST = localDev && origin.indexOf("://localhost") !== -1;
    var IS_VALID_WEB_HOST = !localDev &&
        TRELLIS_CDN_URL.indexOf(origin.replace(/https?:\/\//, "")) !== -1;
    var IS_TRUSTED_DATA = data.source && data.source === "trellis" && messageEvent.isTrusted;
    if (!IS_TRUSTED_DATA) {
        return false;
    }
    return Boolean(IS_VALID_LOCALHOST) || Boolean(IS_VALID_WEB_HOST);
}
